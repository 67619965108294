/**
 * Dependencies
 */
import React, {useEffect, useState} from 'react'
import {Col, Row} from 'reactstrap'
import {Button, Headline, Modal, Paragraph} from '@liquid-design/liquid-design-react'
import {connect} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import useMountEffect from '../utils/useMountEffect'
import {IConfigReducer} from '../store/reducers/configReducer'
import {apiDataSet} from '../models/Api/apiDataSet'
import _ from 'lodash'
import {FormattedMessage, useIntl} from 'react-intl'
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {ELoggingState} from "../models/PLC/ELoggingState";
import {IsNullOrUndefined} from "../utils/plcUtils";
import {ERecipeModels} from '../views/RecipesView'
import {setPlcProperty} from "../utils/plcUtils";
import {apiOpcValue} from "../models/Api/apiOpcValue";
import {UserEventTriggering} from "../models/PLC/UserEventTriggering";
import {useDispatch} from 'react-redux'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {RecordPanel} from '../views/RecordPanel'
import moment from "moment";
import {handleResponse} from "../utils/responseUtils";
import { StepMotorValve } from '../models/PLC/StepMotorValve'
import { DriveControlPID } from '../models/PLC/DriveControlPID'

const styles = {
    cardInner: {
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        padding: '10px'
    }
}

export interface IRecord {
    name: string,
    id: string
}

export interface ICustomFooterProps {
    config: IConfigReducer
}

export enum ESystemCommand {
    RESTART = 'RESTART',
    RESUME = 'RESUME'
}

/**
 * Content
 */
const CustomFooter = (props: ICustomFooterProps) => {
    const {config} = props
    const dispatch = useDispatch()
    const [currentSequence, setCurrentSequence] = useState({
        Id: null,
        Name: '',
        SequenceMacroSteps: []
    })
    const [currentRecord, setCurrentRecord] = useState<apiDataSet>(null)
    const [recordList, setRecordList] = useState<apiDataSet[]>([])
    const [isSequenceRecordModalOpen, setIsSequenceRecordModalOpen] = useState(false)
    const [isSystemResumeModalOpen, setIsSystemResumeModalOpen] = useState(false)
    const [isRecordDialogVisible, setIsRecordDialogVisible] = useState(false)
    const intl = useIntl();
    const isFlowmetersOptionWired = config.Unit.Set.iFactOptionFlowmeters
    const isFiltrateWeightWired = config.Unit.Set.bVisibilityWE201 || config.Unit.Set.bVisibilityWE202 || config.Unit.Set.bVisibilityWE203
    const [isLastChunkReached, setIsLastChunkReached] = useState(false)

    useEffect(() => {
        if (config.Recipe.OperationCtrl.iOperationId !== 0) {
            fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/Get/${config.Recipe.OperationCtrl.iOperationId}`)
                .then((resp) => resp.json())
                .then((sequence) => {
                    setCurrentSequence(sequence)
                })
                .catch((err) => console.error(err))
        } else {
            setCurrentSequence({
                Id: null,
                Name: '',
                SequenceMacroSteps: []
            })
        }
    }, [config.APIBaseUrl, config.Recipe.OperationCtrl.iOperationId])

    useMountEffect(() => {
        updateList();
    })

    //If the iRecordNumber is different than 0 and different than the current dataset id, a new chunk has been created and activated
    if (currentRecord && config.Unit.Inp.iRecordNumber != 0 && config.Unit.Inp.iRecordNumber != currentRecord.Id) {
        //Updating record list and select the "current" one
        updateList();
    }

    function updateList() {
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then((resp) => resp.json())
            .then((recordList: apiDataSet[]) => updateRecordList(recordList))
            .catch((err) => console.error(err))
    }


    function toggleSequence(confirmation: boolean = false) {
        let command: string = "";
        switch (config.Recipe.OperationCtrl.iState) {
            case 0: {
                command = 'Start'
                break;
            }
            case 1: {
                command = 'Pause'
                break;
            }
            case 2: {
                command = 'Resume'
                break;
            }
        }

        if (command === 'Start' && (isOneActuatorManual || !isRecordRunning) && confirmation === false) {
            setIsSequenceRecordModalOpen(true)
        } else {
            setIsSequenceRecordModalOpen(false)
            fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/${command}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(null)
            })
                .then(() => {
                })
                .catch((err) => console.error(err))
        }
    }

    function stopSequence() {
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/Stop`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(null)
        })
            .then(() => {
            })
            .catch((err) => console.error(err))
    }

    function updateRecordList(recordList: apiDataSet[]) {
        const currentRecordSetIndex = _.findIndex(recordList, (r) => r.Current)
        let currentDataSet = currentRecordSetIndex >= 0 ? recordList[currentRecordSetIndex] : null

        //If the new dataset chunk is the number 999, disabling the start button (remain disabled after logging is stopped by the Backend)
        setIsLastChunkReached(currentDataSet && currentDataSet.Name.includes('.999'));

        //Updating current dataset information
        setCurrentRecord(currentDataSet);

        //Updating current data set list
        setRecordList(recordList.filter((r) => !r.Closed))
    }

    function clearCurrentDataSet() {
        let url = `${config.APIBaseUrl}/Unit/api/Unit/v1/DataLogging/ClearCurrentDataSet`

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        }).catch((err) => console.error(err))
    }

    async function getLastChunkNumberAsync(datasetCode: string) {
        try {
            const response = await fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSet/LastChunkNumber`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code: datasetCode
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.text();

            // Assuming the API response returned an integer in the `number` field
            return parseInt(data, 10);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            return null;
        }
    }

    async function onDataSetChangeHandler(e: any) {

        if (e.value !== null) {
            if (e.value.Id) {
                //Current dataset is set with the selected one
                setCurrentRecord(e.value);
            } else {
                //Creating new apiDataSet
                let newRecord = new apiDataSet();
                newRecord.Name = e.value.Name;
                newRecord.Code = e.value.Name;

                //Set current record with the new one
                setCurrentRecord(newRecord);
            }

            //Ensure that the dataset not the last chunk and that the chunk series has not reached the last chunk number
            const lastChunkNumber = await getLastChunkNumberAsync(e.value.Name);
            setIsLastChunkReached(e.value.Name.includes('.999') || lastChunkNumber === 999);

        } else {
            //Null value means unselect dataset
            setCurrentRecord(undefined);

            //Last data set chunk not reached here
            setIsLastChunkReached(true);

            //Clearing current DataSet in the Unit
            clearCurrentDataSet();
        }
    }

    function toggleRecord() {
        let isRecordRunning = config.DataLoggingState === ELoggingState.Running;

        let url = `${config.APIBaseUrl}/Unit/api/Unit/v1/DataLogging/${isRecordRunning ? 'Pause' : 'Start'}`

        if (!isRecordRunning) {
            //Starting recording
            url += `?code=${currentRecord.Code}&name=${currentRecord.Name}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then(handleResponse)
            .then((currentDataSet: apiDataSet) => {
                if (currentRecord.Id === undefined || currentRecord.Id === 0) {
                    //Started new recordset, so replace the current record by this one (with full information)
                    setCurrentRecord(currentDataSet);

                    //Updating current record list by adding the new one (if not already in the list)
                    if (!_.find(recordList, {'Id': currentDataSet.Id})) {
                        setRecordList(prevState => {
                            prevState.push(currentRecord)
                            return _.orderBy(prevState, ['Name'], ['asc']);
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    function stopRecord() {
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/DataLogging/Stop?closeDataSet=false`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then((resp) => resp.json())
            .then(() => {
                setCurrentRecord(undefined)
            })
    }

    function toggleSystem(resumeValue?: ESystemCommand) {
        setIsSystemResumeModalOpen(false)
        let systemCmd: apiOpcValue = new apiOpcValue();
        let userLabel: string;
        systemCmd.Value = true;
        if (config.OPC.Sts.iCurrentStatus === 0) {
            systemCmd.NodeId = config.OPC.Inp.NodeId + ".bCommandHolding"
            userLabel = 'label.event.CommandSystemHold'
            setPlcProperty(config, systemCmd, {
                userLabel,
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction
            }, dispatch)
        } else {
            if (!resumeValue) {
                setIsSystemResumeModalOpen(true)
            } else {
                systemCmd.NodeId = config.OPC.Inp.NodeId + (resumeValue === ESystemCommand.RESTART ? '.bCommandRestarting' : '.bCommandResume')
                userLabel = (resumeValue === ESystemCommand.RESTART ? 'label.event.CommandSystemRestart' : 'label.event.CommandSystemResume')
                setPlcProperty(config, systemCmd, {
                    userLabel,
                    unit: '',
                    eventTriggering: UserEventTriggering.BeforeAction
                }, dispatch)
            }
        }
    }

    function jumpMacroStep() {

        let cmdjump: apiOpcValue = new apiOpcValue();
        let userLabel: string;
        cmdjump.Value = true;

        cmdjump.NodeId = config.Recipe.Phase[0].NodeId + ".bJump"
        userLabel = 'label.event.CommandRecipeJump'


        setPlcProperty(config, cmdjump, {
            userLabel,
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch)
    }

    function generateStatusText() {
        const currentStatus = config.OPC.Sts.iCurrentStatus
        switch (currentStatus) {
            case 0 : {
                return (
                    <FormattedMessage id='label.SystemControlSystemRunning'/>
                );
            }
            case 1 : {
                return (
                    <FormattedMessage id='label.SystemControlSystemHoldingValves'/>
                );
            }
            case 2 : {
                return (
                    <FormattedMessage id='label.SystemControlSystemHoldingPumps'/>
                );
            }
            case 3 : {
                return (
                    <FormattedMessage id='label.SystemControlSystemHold'/>
                );
            }
            case 4 : {
                return (
                    <FormattedMessage id='label.SystemControlSystemRestarting'/>
                );
            }
        }
    }

    function generateMacroStepInformation() {
        if (currentSequence.SequenceMacroSteps && currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1] && (currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].ModelMacroStepId - 1 === ERecipeModels.DIAFILTRATION || currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].ModelMacroStepId - 1 === ERecipeModels.CONCENTRATION)) {
            return (
                <div style={{color: 'rgba(45, 45, 45, .4)'}}>
                    {displayDetails && currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].ModelMacroStepId - 1 === ERecipeModels.CONCENTRATION
                        && (isFlowmetersOptionWired || isFiltrateWeightWired) && (
                            <>
                                <Headline type="H6" style={{color: 'rgba(45, 45, 45, .6)'}}>
                                    <FormattedMessage id='label.SequenceControlMacrostepInfo'/>
                                </Headline>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{textAlign: 'left'}}>
                                        <FormattedMessage id='label.SequenceControlConversionFactor' values={{
                                            conversionFactor: config.Unit.Sts.rConversionFactor,
                                        }}/>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <FormattedMessage id='label.SequenceControlTotalVolumeConcentrationFactor'
                                                          values={{
                                                              totalVolumeConcentrationFactor: config.Unit.Sts.rVolumeConcentrationFactor,
                                                          }}/>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        <FormattedMessage id='label.SequenceControlCurrentConcentration' values={{
                                            currentConcentration: config.Unit.Sts.rCurrentConcentration,
                                        }}/>
                                    </div>
                                </div>
                            </>
                        )}
                    {currentSequence.Name && currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].ModelMacroStepId - 1 === ERecipeModels.DIAFILTRATION
                        && (isFlowmetersOptionWired || isFiltrateWeightWired) && (
                            <>
                                <Headline type="H6" style={{color: 'rgba(45, 45, 45, .6)'}}>
                                    <FormattedMessage id='label.SequenceControlMacrostepInfo'/>
                                </Headline>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{textAlign: 'left'}}>
                                        <FormattedMessage id='label.SequenceControlDiafiltrationVolume' values={{
                                            diafiltrationVolume: config.Unit.Sts.rDiafiltrationVolume,
                                        }}/>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {config.Unit.Set.rNumberDiafiltrationVolume > 0 &&
                                            <FormattedMessage id='label.SequenceControlFinalDiafiltrationVolume'
                                                              values={{
                                                                  finalDiafiltrationVolume: config.Unit.Sts.rFinalDiafiltrationVolume,
                                                              }}/>}
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            );
        }
    }

    //Getting logging state from store
    let isRecordRunning = config.DataLoggingState === ELoggingState.Running;
    let isOneActuatorManual = false;
    if (
      (config.Instances.ControlModules['P001/SIC001'] && (config.Instances.ControlModules['P001/SIC001'] as DriveControlPID).Set_bMode)
      ||
      (config.Instances.ControlModules['P401/SIC401'] && (config.Instances.ControlModules['P401/SIC401'] as DriveControlPID).Set_bMode)
      ||
      (config.Instances.ControlModules['PCV101'] && (config.Instances.ControlModules['PCV101'] as StepMotorValve).Set_bMode)
      ||
      (config.Instances.ControlModules['PCV201'] && (config.Instances.ControlModules['PCV201'] as StepMotorValve).Set_bMode)
    ) {
        isOneActuatorManual = true
    }

    const displayDetails = currentSequence.Name && !(config.Recipe.OperationCtrl.iCurrentMacroStep > currentSequence.SequenceMacroSteps.length)

    return (
        <>

            <div style={{
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                backgroundColor: '#F3F3F7'
            }}>
                <div className='adapt-left' style={styles.cardInner}>
                    <Headline type="H6">
                        <FormattedMessage id='label.SystemControlTitle'/>
                    </Headline>
                    <Headline type="H4" style={{
                        color: config.OPC.Sts.iCurrentStatus === 0 ? 'rgb(45, 45, 45, .6)' : 'rgb(255,0,0)',
                        animation: config.OPC.Sts.iCurrentStatus !== 0 ? 'blinker 2s linear infinite' : 'none'
                    }}>
                        <FormattedMessage id={'label.Status'}/>: {generateStatusText()}
                    </Headline>
                    <Button style={{width: '100%', marginTop: '5px'}}
                            icon='mediaPlay'
                            disabled={config.OPC.Sts.iCurrentStatus !== 3}
                            onClick={() => toggleSystem()}>{<FormattedMessage
                        id='label.SystemControlResume'/>}</Button>
                    <Button appearance='highlight' style={{width: '100%', marginTop: '5px'}}
                            icon='attention'
                            disabled={config.OPC.Sts.iCurrentStatus !== 0}
                            onClick={() => toggleSystem()}>{<FormattedMessage
                        id='label.SystemControlHold'/>}</Button>
                </div>
                <div className='adapt' style={styles.cardInner}>
                    <Row>
                        <div className='col-6'>
                            <Headline type="H6">
                                <FormattedMessage id='label.SequenceControlTitle'/>
                            </Headline>
                            <Headline type="H6" style={{color: 'rgba(45, 45, 45, .6)'}}>
                                {currentSequence.Name ?
                                    <FormattedMessage id='label.SequenceControlSequenceRunning' values={{
                                        sequenceName: currentSequence.Name,
                                    }}/> : <FormattedMessage id='label.SequenceControlNoSequenceRunning'/>}
                            </Headline>
                            <div style={{
                                color: 'rgba(45, 45, 45, .4)',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <div>
                                    <FormattedMessage id='label.SequenceControlInfoRunningTime' values={{
                                        runTime: config.Recipe.OperationCtrl.RunTimeTotalizer.dHours.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.RunTimeTotalizer.dMinutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.RunTimeTotalizer.dSeconds.toLocaleString(undefined, {minimumIntegerDigits: 2}),
                                    }}/>

                                </div>
                                <div>
                                    <FormattedMessage id='label.SequenceControlInfoPauseTime' values={{
                                        pauseTime: config.Recipe.OperationCtrl.PauseTimeTotalizer.dHours.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.PauseTimeTotalizer.dMinutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.PauseTimeTotalizer.dSeconds.toLocaleString(undefined, {minimumIntegerDigits: 2}),
                                    }}/>
                                </div>
                                <div>
                                    <FormattedMessage id='label.SequenceControlInfoHoldTime' values={{
                                        holdTime: config.Recipe.OperationCtrl.HoldTimeTotailzer.dHours.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.HoldTimeTotailzer.dMinutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.OperationCtrl.HoldTimeTotailzer.dSeconds.toLocaleString(undefined, {minimumIntegerDigits: 2})
                                    }}/>
                                </div>
                            </div>
                            <Button style={{width: '100%', marginTop: '5px'}}
                                    disabled={!currentSequence.Name || config.Recipe.OperationCtrl.iState === 3}
                                    icon={config.Recipe.OperationCtrl.iState === 1 ? 'mediaPause' : 'mediaPlay'}
                                    onClick={() => toggleSequence()}>{config.Recipe.OperationCtrl.iState === 1 ?
                                <FormattedMessage id='label.SequenceControlPause'/> :
                                <FormattedMessage id='label.SequenceControlStart'/>}
                            </Button>
                            <Button style={{width: '100%', marginTop: '5px'}}
                                    disabled={!currentSequence.Name || config.Recipe.OperationCtrl.iState === 0 || config.Recipe.OperationCtrl.iState === 3}
                                    icon='mediaStop'
                                    onClick={() => stopSequence()}>
                                <FormattedMessage id='label.Stop'/>
                            </Button>
                        </div>
                        <div className='col-6'>
                            {!!currentSequence.Name && (
                                <>
                                    <Headline type="H6">
                                        <FormattedMessage id='label.SequenceControlMacrostepTitle'/>
                                    </Headline>
                                    <Headline type="H6" style={{color: 'rgba(45, 45, 45, .6)'}}>
                                        <FormattedMessage id='label.SequenceControlMacroStepRunning' values={{
                                            currentStep: displayDetails ? currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].Order + " - " + ERecipeModels[currentSequence.SequenceMacroSteps[config.Recipe.OperationCtrl.iCurrentMacroStep - 1].ModelMacroStepId - 1] : 0
                                        }}/>
                                    </Headline>
                                    <div style={{color: 'rgba(45, 45, 45, .4)'}}>
                                        {config.Recipe.Phase[0].iPromptType == 2 ? (
                                            <FormattedMessage id='label.WaitingForStart'/>
                                        ) : (
                                            <FormattedMessage id='label.SequenceControlInfoRunningTime' values={{
                                                runTime: config.Recipe.Phase[0].CurrentStepTimeTotalizer.dHours.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.Phase[0].CurrentStepTimeTotalizer.dMinutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" + config.Recipe.Phase[0].CurrentStepTimeTotalizer.dSeconds.toLocaleString(undefined, {minimumIntegerDigits: 2}),
                                            }}/>
                                        )}
                                    </div>
                                    <Button style={{width: '100%', marginTop: '5px', marginBottom: '5px'}}
                                            disabled={!currentSequence.Name || config.Recipe.OperationCtrl.iState === 0 || config.Recipe.OperationCtrl.iState === 3}
                                            icon='mediaFastForward'
                                            onClick={() => jumpMacroStep()}>
                                        <FormattedMessage id='label.Jump'/>
                                    </Button>
                                    {generateMacroStepInformation()}
                                </>)}
                        </div>
                    </Row>
                </div>
                <div className='adapt-right' style={styles.cardInner}>
                    <Headline type="H6">
                        <FormattedMessage id='label.RecordingsTitle'/> <span style={{cursor: 'pointer'}}
                                                                             onClick={() => setIsRecordDialogVisible(true)}>⚙︝</span>
                    </Headline>
                    <ComboBox
                        allowCustom={true}
                        data={recordList}
                        textField="Name"
                        dataItemKey="Id"
                        disabled={isRecordRunning}
                        placeholder={intl.formatMessage({id: 'label.SelectExistingOrEnterNewName'})}
                        value={currentRecord ? currentRecord : null}
                        onChange={onDataSetChangeHandler}
                        onOpen={(e) => {
                            updateList()
                        }}
                        onFocus={(e) => {
                            if (!currentRecord) {
                                onDataSetChangeHandler({value: {Name: `${moment().format('YYYY-MM-DD_HH-mm')}-LOG.001`}})
                            }
                        }}
                        style={{width: '100%'}}
                    />
                    <Button style={{width: '100%', marginTop: '5px'}}
                            disabled={isRecordRunning || IsNullOrUndefined(currentRecord) || isLastChunkReached}
                            icon={isRecordRunning ? '' : 'mediaPlay'}
                            onClick={() => toggleRecord()}>{isRecordRunning ? <>🔴</> : null} {isRecordRunning ?
                        <FormattedMessage id='label.Recording'/> : <FormattedMessage id='label.Start'/>}
                    </Button>
                    <Button style={{width: '100%', marginTop: '5px'}}
                            disabled={!isRecordRunning || IsNullOrUndefined(currentRecord)}
                            icon='mediaStop'
                            onClick={() => toggleRecord()}>
                        <FormattedMessage id='label.Stop'/></Button>
                    <div><FormattedMessage id='label.Recording'/>:&nbsp;{config.LoggingElapsedTime}</div>
                </div>
            </div>

            {isSequenceRecordModalOpen && (
                <Dialog title={<FormattedMessage id='label.StartAnyway' />} closeIcon={false}>
                    <ul style={{ paddingLeft: 8 }}>
                        { !isRecordRunning && (<li><FormattedMessage id="label.NoRecordingRunning"/></li>) }
                        { isOneActuatorManual && (<li><FormattedMessage id="label.OneActuatorIsManual"/></li>) }
                    </ul>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => toggleSequence(true)}>
                            <FormattedMessage id='label.Yes'/>
                        </button>
                        <button className="k-button" onClick={() => setIsSequenceRecordModalOpen(false)}>
                            <FormattedMessage id='label.No'/>
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {isSystemResumeModalOpen && (
                <Dialog title={intl.formatMessage({id: 'label.ResumeSystem'})} closeIcon={false}>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => toggleSystem(ESystemCommand.RESUME)}>
                            <FormattedMessage id='label.SystemControlResume'/>
                        </button>
                        <button className="k-button" onClick={() => toggleSystem(ESystemCommand.RESTART)}>
                            <FormattedMessage id='label.SystemControlRestart'/>
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            {isRecordDialogVisible && (
                <Modal label={intl.formatMessage({id: 'label.RecordManagement'})} open={isRecordDialogVisible}
                       onClose={() => setIsRecordDialogVisible(false)}>
                    <div className='container'>
                        <RecordPanel/>
                    </div>
                </Modal>)}


        </>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(CustomFooter, (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.locale === nextProps.config.locale &&
            prevProps.config.localeRecords === nextProps.config.localeRecords &&
            prevProps.config.Recipe === nextProps.config.Recipe &&
            prevProps.config.DataLoggingState === nextProps.config.DataLoggingState &&
            prevProps.config.LoggingElapsedTime === nextProps.config.LoggingElapsedTime &&
            prevProps.config.OPC === nextProps.config.OPC &&
            prevProps.config.Unit === nextProps.config.Unit &&
            prevProps.config.Instances === nextProps.config.Instances
        )
    })
)
